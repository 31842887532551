import type { ReactElement } from 'react';

const Loading = (): ReactElement => (
	<div
		className={`
				flex items-center justify-center
				custom-h-full
			`}
	>
		<p className="font-extralight">Loading...</p>
	</div>
);

export default Loading;
