import { InlineCheckBox } from './CheckBox';

import type { ReactElement } from 'react';

interface TermsAndConditionsProps {
	hasAccepetedTerms: boolean;
	changeHasAcceptedTerms: () => void;
}

const renderTermsAndConditionsTitle = (): ReactElement => {
	return (
		<div className="flex flex-row p-1">
			<span className="text-xs gray-800">{`I agree to the`} &nbsp;</span>
			<a
				className={`
                    text-xs gray-800 text-blue-700 
                    decoration-solid underline 
                    visited:text-purple-600
                `}
				href="https://lettheinsideout.com/terms-conditions"
				target={`_blank`}
			>
				{`terms and conditions`}
			</a>
		</div>
	);
};

const TermsAndConditions = (props: TermsAndConditionsProps): ReactElement => (
	<div className="flex flex-row justify-end items-center mb-4">
		<InlineCheckBox
			id={`terms-and-conditions`}
			className="gray-800 text-xs w-full px-1"
			label={renderTermsAndConditionsTitle()}
			checked={props.hasAccepetedTerms}
			onChange={props.changeHasAcceptedTerms}
			highlight={false}
		/>
	</div>
);

export default TermsAndConditions;
