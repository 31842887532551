import TickCircleIcon from './tick_circle.png';
import CrossIcon from './cross.png';

import type { ReactElement } from 'react';

interface PasswordValidationProps {
	password?: string | undefined | null;
}

interface RuleCheckboxProps {
	ruleName: string;
	valid: boolean;
}

const RuleCheckbox = (props: RuleCheckboxProps): ReactElement => {
	return (
		<div className={`flex flex-row items-center mb-2`}>
			<img className={props.valid ? `w-4 h-4 mr-2` : `w-3 h-3 mr-2`} src={props.valid ? TickCircleIcon : CrossIcon} />
			<span className={`grey-900 mr-3`}>{props.ruleName}</span>
		</div>
	);
};

export const PasswordValidation = (props: PasswordValidationProps): ReactElement => {
	const hasLengthValidation = !!props.password && props.password.length >= 8;
	const hasNumberValidation = !!props.password && /[\p{N}]/u.test(props.password);
	const hasUppercaseValidation = !!props.password && /[\p{Lu}]/u.test(props.password);
	const hasLowercaseValidation = !!props.password && /[\p{Ll}]/u.test(props.password);

	const rules: RuleCheckboxProps[] = [
		{ ruleName: `8 characters`, valid: hasLengthValidation },
		{ ruleName: `1 number`, valid: hasNumberValidation },
		{ ruleName: `1 uppercase character`, valid: hasUppercaseValidation },
		{ ruleName: `1 lowercase character`, valid: hasLowercaseValidation },
	];

	return (
		<div>
			<div className="mb-1">
				<span className={`text-gray-500`}>{`Your password must contain:`}</span>
			</div>
			<div className={`flex flex-col flex-wrap mb-3`}>
				{rules.map(rule => (
					<RuleCheckbox key={rule.ruleName} {...rule} />
				))}
			</div>
		</div>
	);
};

export const isPasswordValid = (password: string): boolean => {
	if (!password) {
		return false;
	} else if (password.length < 8) {
		return false;
	} else if (!/[\p{Ll}]/u.test(password)) {
		return false;
	} else if (!/[\p{Lu}]/u.test(password)) {
		return false;
	} else if (!/[\p{N}]/u.test(password)) {
		return false;
	}
	return true;
};
