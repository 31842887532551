import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Logo from './insideout-logo.png';
import { handleRequest } from '../utils/request';
import { log } from '../utils/logger';
import { getExternalProviderAuthSessionId, isAuthenticated, setSessionState } from '../utils/auth';
import { useModifyPageTitle } from '../Hooks/hooks';
import { isPasswordValid, PasswordValidation } from '../Component/PasswordValidation';
import TermsAndConditions from '../Component/TermsAndConditions';

import type { ReactElement } from 'react';

const SignUpForm = (): ReactElement => {
	const [password, setPassword] = useState(``);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const [loggedIn, setLoggedIn] = useState(false);
	const [loading, setLoading] = useState(false);
	const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

	const sessionId = getExternalProviderAuthSessionId();

	const canSignUp = (): boolean => {
		return hasAcceptedTerms && isPasswordValid(password);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		event.preventDefault();
		if (!sessionId) {
			setErrorMessage(`Unable to retrieve session information`);
			return;
		}
		setLoading(true);
		const [error, reqResult] = await handleRequest({
			route: `signUp`,
			data: { sessionId, password },
		});
		setLoading(false);

		if (error || !reqResult) {
			setErrorMessage(error?.message ?? `Something went wrong`);
			return;
		}

		const { data } = reqResult;
		const [setSessionStateErr] = await setSessionState(data.user.token, `v1`);
		if (setSessionStateErr) {
			log.unhappy(`Session state set error`, { error: setSessionStateErr.message });
			setErrorMessage(setSessionStateErr.message);
			return;
		}
		setLoggedIn(true);
	};

	if (loggedIn) {
		return <Redirect to="/" />;
	}

	return (
		<div
			className={`
			flex items-center justify-center
			min-h-full 
			py-12 px-4 sm:px-6 lg:px-8
			max-w-[350px]
		`}
		>
			<div className="max-w-md w-full space-y-8">
				<div>
					<img src={Logo} className={`mx-auto`} />
				</div>
				{errorMessage && (
					<div
						className={`
						flex items-center
						px-3 py-2
						w-full
						bg-red-100
						rounded
						text-sm
					`}
					>
						There was an error. {errorMessage}
					</div>
				)}
				<form className="mt-8 space-y-6" onSubmit={handleSubmit}>
					<span className="font-medium">
						Set your password in order to complete the onboarding and be able to use the app
					</span>
					<input type="hidden" name="remember" value="true" />
					<div className="rounded-md shadow-sm -space-y-px">
						<div className={`mt-2`}>
							<label className="sr-only text-sm">Password</label>
							<input
								id="password"
								name="password"
								type="password"
								autoComplete="current-password"
								required
								className={`
									block
									relative
									w-full
									px-3 py-2
									border border-gray-300 rounded-b-md focus:border-gray-500
									placeholder-gray-500
									text-gray-900 sm:text-sm 
									focus:outline-none
									focus:ring-gray-500
									focus:z-10
									appearance-none
								`}
								placeholder="Enter your password"
								value={password}
								onChange={(event): void => setPassword(event.target.value)}
							/>
						</div>
					</div>
					<PasswordValidation password={password} />
					<TermsAndConditions
						hasAccepetedTerms={hasAcceptedTerms}
						changeHasAcceptedTerms={(): void => setHasAcceptedTerms(!hasAcceptedTerms)}
					/>
					<div>
						<button
							type="submit"
							className={`
								group
								flex justify-center
								relative
								w-full
								mt-2
								py-3 px-4
								border border-transparent rounded-md
								text-white text-sm
								font-medium
								${canSignUp() ? `bg-primary hover:bg-primary-dark` : `bg-gray-300`}
								focus:outline-none
								focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
							`}
							disabled={!canSignUp() || loading}
						>
							{loading ? `Please wait...` : `Sign Up`}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

const SignUp = (): ReactElement => {
	useModifyPageTitle(`SignUp`);
	if (isAuthenticated()) {
		return <Redirect to="/" />;
	}

	return (
		<div
			className={`
				flex justify-center items-center
				h-full
				bg-gray-100
			`}
		>
			<SignUpForm />
		</div>
	);
};

export default SignUp;
