import { useState } from 'react';
import { Redirect } from 'react-router-dom';

import Logo from './insideout-logo.png';
import { handleRequest } from '../utils/request';
import { log } from '../utils/logger';
import { isAuthenticated, setExternalProviderAuthSessionId, setSessionState } from '../utils/auth';
import { useModifyPageTitle } from '../Hooks/hooks';

import type { ReactElement } from 'react';

const LoginForm = (): ReactElement => {
	const [email, setEmail] = useState(``);
	const [password, setPassword] = useState(``);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const [loggedIn, setLoggedIn] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		event.preventDefault();
		setLoading(true);
		const [error, reqResult] = await handleRequest({
			route: `login`,
			data: { email, password, [`device_id`]: `web_app` },
		});
		setLoading(false);

		if (error || !reqResult) {
			setErrorMessage(error?.message ?? `Something went wrong`);
			return;
		}

		const { data } = reqResult;
		const [setSessionStateErr] = await setSessionState(data.token, `v1`);
		if (setSessionStateErr) {
			log.unhappy(`Session state set error`, { error: setSessionStateErr.message });
			setErrorMessage(setSessionStateErr.message);
			return;
		}
		setLoggedIn(true);
	};

	const connectWithProvicer = async (provider: string): Promise<void> => {
		setLoading(true);
		const [error, response] = await handleRequest({
			route: `external-provider-connect-url`,
			query: { provider },
		});
		setLoading(false);

		if (error || !response) {
			setErrorMessage(error?.message ?? `Something went wrong`);
			return;
		}
		setExternalProviderAuthSessionId(response.data.sessionId);
		window.location.href = response.data.url;
	};

	if (loggedIn) {
		return <Redirect to="/" />;
	}

	return (
		<div
			className={`
			flex items-center justify-center
			min-h-full 
			py-12 px-4 sm:px-6 lg:px-8
			max-w-[350px]
		`}
		>
			<div className="max-w-md w-full space-y-8">
				<div>
					<img src={Logo} className={`mx-auto`} />
				</div>
				{errorMessage && (
					<div
						className={`
						flex items-center
						px-3 py-2
						w-full
						bg-red-100
						rounded
						text-sm
					`}
					>
						There was an error. {errorMessage}
					</div>
				)}
				<form className="mt-8 space-y-6" onSubmit={handleSubmit}>
					<input type="hidden" name="remember" value="true" />
					<div className="rounded-md shadow-sm -space-y-px">
						<div>
							<label className="sr-only text-sm">Email address</label>
							<input
								id="email-address"
								name="email"
								type="email"
								autoComplete="email"
								required
								className={`
									block
									relative
									w-full
									px-3 py-2
									border border-gray-300 rounded-t-md focus:border-gray-500
									placeholder-gray-500
									text-gray-900 sm:text-sm
									focus:outline-none
									focus:ring-gray-500
									focus:z-10
									appearance-none
								`}
								placeholder="Email address"
								value={email}
								onChange={(event): void => setEmail(event.target.value)}
							/>
						</div>
						<div className={`mt-2`}>
							<label className="sr-only text-sm">Password</label>
							<input
								id="password"
								name="password"
								type="password"
								autoComplete="current-password"
								required
								className={`
									block
									relative
									w-full
									px-3 py-2
									border border-gray-300 rounded-b-md focus:border-gray-500
									placeholder-gray-500
									text-gray-900 sm:text-sm 
									focus:outline-none
									focus:ring-gray-500
									focus:z-10
									appearance-none
								`}
								placeholder="Password"
								value={password}
								onChange={(event): void => setPassword(event.target.value)}
							/>
						</div>
					</div>

					<div>
						<button
							type="submit"
							className={`
								group
								flex justify-center
								relative
								w-full
								mt-2
								py-3 px-4
								border border-transparent rounded-md
								text-white text-sm
								font-medium
								bg-primary hover:bg-primary-dark
								focus:outline-none
								focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
							`}
							disabled={loading}
						>
							{loading ? `Please wait...` : `Sign In`}
						</button>
					</div>
				</form>

				<div className="inline-flex items-center justify-center w-full">
					<hr className="w-64 h-px bg-gray-200 border-0 dark:bg-gray-700" />
					<span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-gray-100 left-1/2"> OR </span>
				</div>

				<div>
					<button
						className={`
								group
								flex justify-between
								relative
								w-full
								mt-2
								py-2 px-4
								border border-transparent rounded-md
								text-white text-sm
								font-medium
								bg-blue-700 hover:bg-blue-800
								focus:outline-none
								focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
							`}
						disabled={loading}
						onClick={(): void => {
							void connectWithProvicer(`health-key`);
						}}
					>
						<div>
							<img
								className="w-8 h-8"
								alt="logo"
								src="https://app.sandbox.healthkey.health/static/media/logo_no_text.f8bccc28a43a9edf9f60.png"
							/>
						</div>
						<div className="py-1 px-4">
							<span>{loading ? `Please wait...` : `Sign In with HealthKey`}</span>
						</div>
					</button>
				</div>
			</div>
		</div>
	);
};

const Login = (): ReactElement => {
	useModifyPageTitle(`Login`);

	if (isAuthenticated()) {
		return <Redirect to="/" />;
	}

	return (
		<div
			className={`
				flex justify-center items-center
				h-full
				bg-gray-100
			`}
		>
			<LoginForm />
		</div>
	);
};

export default Login;
