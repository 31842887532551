import { useState } from 'react';
import Button from '../../Component/Button';

import type { ReactElement } from 'react';
import type { ISubscriptionProduct } from './Products';

interface ConfirmPurchaseMFPModalContentProps {
	products?: ISubscriptionProduct[];
	onPurchaseCallback: (productId: string) => void;
}

const ConfirmPurchaseModalContent = (props: ConfirmPurchaseMFPModalContentProps): ReactElement => {
	const { products } = props;
	const [loading, setLoading] = useState(false);

	if (!products || !products.length)
		return (
			<>
				<span>{`No product selected. Please go back and select a product`}</span>
			</>
		);

	const productInfo = products[0];
	return (
		<>
			<div className="flex flex-wrap">
				<span className="text-lg font-medium">{productInfo?.name}</span>
			</div>
			<hr className="mt-2 mb-2" />
			<div className="flex flex-wrap mb-3">
				<span className="text-sm">
					In order to purchase we will ask you to create an InsideOut account, if you don't have one already, to use the
					Mobile App.
				</span>
			</div>
			<hr className="mt-3 mb-3" />
			<div className="flex flex-col w-full">
				{products.map(product => (
					<div className="flex flex-row justify-between mb-6">
						<div className="flex flex-col mt-2">
							<span className="text-sm font-normal">{`Price: £${product.price}`}</span>
							<span className="text-sm font-normal italic">{`every ${product.frequency}`}</span>
						</div>
						<Button
							className={`
								mt-2 bg-primary 
								text-gray-100 font-bold
								rounded-full
								px-2 py-2
								dark:hover:bg-website-dark-blue dark:focus:ring-green-400
               	 			`}
							variant="primary"
							loading={loading}
							onClick={(): void => {
								setLoading(true);
								props.onPurchaseCallback(product.id);
							}}
							disabled={loading}
							text="Purchase With HealthKey"
						/>
					</div>
				))}
			</div>
		</>
	);
};

export default ConfirmPurchaseModalContent;
