import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import HomePage from './Page/Home';
import LoginPage from './Page/Login';
import LogoutPage from './Page/Logout';
import ExchangeCode from './Page/ExchangeCode';
import SignUp from './Page/HealthKeySignUp';

import type { ReactElement } from 'react';

const Routes = (): ReactElement => {
	return (
		<Switch>
			<Route exact path="/" component={HomePage} />
			<Route exact path="/login" component={LoginPage} />
			<Route exact path="/oauth/redirect" component={ExchangeCode} />
			<Route exact path="/signup" component={SignUp} />
			<Route exact path="/logout" component={LogoutPage} />
		</Switch>
	);
};

const App = (): ReactElement => {
	return (
		<div className="h-full">
			<RecoilRoot>
				<Router>
					<Routes />
				</Router>
			</RecoilRoot>
		</div>
	);
};

export default App;
