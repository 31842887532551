import { useEffect, useState } from 'react';
import { handleRequest } from '../../utils/request';
import { getExternalProviderAuthSessionId, getPatientIdFromState, isAuthenticated } from '../../utils/auth';
import Button from '../../Component/Button';

import type { ReactElement } from 'react';

interface IHealthKeyUserInfo {
	email: string;
	balance: number;
}

interface IHealthKeyCreditsProps {
	onCanPurchase: (purchase: boolean) => void;
}

const HealthKeyCredits = (props: IHealthKeyCreditsProps): ReactElement => {
	const [loading, setLoading] = useState(false);
	const [credits, setCredits] = useState(`0.0`);
	const [toConnect, setToConnect] = useState(false);

	const loadUserInfo = async (): Promise<IHealthKeyUserInfo | null> => {
		let routeInfo = { route: ``, params: {} };
		if (isAuthenticated()) {
			routeInfo = {
				route: `provider-user-info-authenticated`,
				params: { patientId: `${getPatientIdFromState()}` },
			};
		} else {
			routeInfo = {
				route: `provider-user-info-not-authenticated`,
				params: { sessionId: `${getExternalProviderAuthSessionId()}` },
			};
		}
		if (!routeInfo.route) return null;
		const [error, reqResult] = await handleRequest(routeInfo);
		if (error || reqResult?.status !== 200) {
			return null;
		}
		return reqResult.data as IHealthKeyUserInfo;
	};

	const loadCredits = async (): Promise<void> => {
		setLoading(true);
		const userInfo = await loadUserInfo();
		if (!userInfo) {
			setToConnect(true);
			props.onCanPurchase(false);
		} else {
			setToConnect(false);
			setCredits(`${userInfo.balance}`);
			props.onCanPurchase(true);
		}
		setLoading(false);
	};

	useEffect(() => {
		void loadCredits();
	}, []);

	return (
		<div className="">
			{loading ? (
				<span>{`Fetching Health Key Info...`}</span>
			) : (
				<>
					{toConnect ? (
						<div className="w-full flex justify-center items-center p-4 border-b border-gray-200 bg-gray-50 dark:bg-blue-800 dark:border-blue-800">
							<div className="mr-2">
								<img
									className="w-8 h-8"
									alt="logo"
									src="https://app.sandbox.healthkey.health/static/media/logo_no_text.f8bccc28a43a9edf9f60.png"
								/>
							</div>
							<div className="p-1 mr-2">
								<span className="text-gray-200">{`Connect to Health Key to in order to continue`}</span>
							</div>
							<div className="md:flex1 p-1">
								<Button
									className=""
									onClick={async (): Promise<void> => loadCredits()}
									loading={loading}
									text={`Connect`}
								/>
							</div>
						</div>
					) : (
						<div className="flex justify-end sm:px-8 px-1 py-3">
							<span className="font-medium text-blue-900 p-1">{`HealthKey balance`}</span>
							<span
								className={`
									bg-blue-100
									dark:bg-blue-900 dark:text-gray-200
									text-gray-200 text-lg font-medium 
									mr-2 px-2.5 py-0.5 
									rounded-md
								`}
							>
								{`£${credits}`}
							</span>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default HealthKeyCredits;
