import { v4 as uuidV4 } from 'uuid';

import config from '../config/config';
import { log } from '../utils/logger';
import { getSessionState, hasSessionExpired } from '../utils/auth';

import type { IBodyBase } from '../definitions';

export type RequestErrorType =
	| `PARSE_API_REQUEST_ERROR`
	| `NETWORK_API_REQUEST_ERROR`
	| `UNKNOWN_HANDLE_API_REQUEST_ERROR`
	| `UNAUTHORISED`
	| `UPDATE_USER_CONFLICT`;

interface IRequestError {
	type: RequestErrorType;
	message: string;
}

interface IAPIResponse {
	success: boolean;
	status: number;
	data: Record<string, any>;
}

interface IFetchOpts {
	method: string;
	body: string | undefined;
	headers: {
		[key: string]: string;
	};
	mode?: RequestMode;
	credentials?: RequestCredentials;
}

interface IHandleRequest {
	route: string;
	params?: Record<string, string>;
	query?: Record<string, string>;
	data?: IBodyBase;
}

interface IAPIRequestOpts {
	method: string;
	url: string;
	reqBody?: IBodyBase;
	isNewApi: boolean;
}

const getRequestOptions = (reqParams: IHandleRequest): IAPIRequestOpts | undefined => {
	switch (reqParams.route) {
		case `login`:
			return {
				method: `post`,
				url: `${config.API.URL}/authentication/generate`,
				reqBody: reqParams.data,
				isNewApi: false,
			};
		case `logout`:
			return {
				method: `delete`,
				url: `${config.API.URL}/authentication/removeToken?device_id=${reqParams.query?.deviceId}`,
				isNewApi: false,
			};
		case `external-provider-connect-url`:
			return {
				method: `get`,
				url: `${config.API.URL}/authentication/providers/connect-url?provider=${reqParams.query?.provider}`,
				isNewApi: false,
			};
		case `exchange-code-tokens`:
			return {
				method: `post`,
				url: `${config.API.URL}/authentication/providers/tokens`,
				reqBody: reqParams.data,
				isNewApi: false,
			};
		case `signUp`:
			return {
				method: `post`,
				url: `${config.API.URL}/authentication/providers/signup`,
				reqBody: reqParams.data,
				isNewApi: false,
			};
		case `profile`:
			return {
				method: `get`,
				url: `${config.API.URL}/patients/${reqParams.params?.patientId}`,
				isNewApi: false,
			};
		case `provider-user-info-authenticated`:
			return {
				method: `get`,
				url: `${config.API.URL}/patients/${reqParams.params?.patientId}/provider`,
				isNewApi: false,
			};
		case `provider-user-info-not-authenticated`:
			return {
				method: `get`,
				url: `${config.API.URL}/providers/sessions/${reqParams.params?.sessionId}/user`,
				isNewApi: false,
			};
		case `get-subscriptions`:
			return {
				method: `get`,
				url: `${config.API.BOOKINGS_URL}/users/patient/subscriptions?status=all&type=personal`,
				isNewApi: true,
			};
		case `purchase-subscription`:
			return {
				method: `post`,
				url: `${config.API.BOOKINGS_URL}/users/patient/subscription`,
				reqBody: reqParams.data,
				isNewApi: true,
			};
		case `get-products`:
			return {
				method: `get`,
				url: `${config.API.BOOKINGS_URL}/users/patient/products`,
				isNewApi: true,
			};
		default:
			return undefined;
	}
};

/**
 * Trigger a HTTP request to the API
 * @param method - The HTTP method to use
 * @param url - The relative URL of the resource in the API
 * @param reqBody - The body JSON
 */
export const handleRequest = async (
	reqParams: IHandleRequest,
): Promise<[IRequestError | undefined, IAPIResponse | undefined]> => {
	try {
		const { method, url, reqBody, isNewApi } = getRequestOptions(reqParams) ?? {};
		if (!method || !url) {
			return [{ type: `UNKNOWN_HANDLE_API_REQUEST_ERROR`, message: `Unable to get request options` }, undefined];
		}
		const opts: IFetchOpts = {
			method,
			body: reqBody ? JSON.stringify(reqBody) : undefined,
			headers: {
				'content-type': `application/json`,
			},
		};
		if (isNewApi) {
			opts.headers[`correlation-id`] = uuidV4();
			opts.credentials = `include`;
			opts.mode = `cors`;
		}

		const sessionState = getSessionState();
		if (sessionState?.authenticated) {
			opts.headers.authorization = `Bearer ${sessionState.accessToken}`;
		}

		log.trace(`Sending request to API`, {
			method: method.toLowerCase(),
			url,
			body: reqBody,
		});

		const res = await fetch(url, opts);
		const resBody = res.status === 202 ? {} : await res.json();

		if (res.status === 403) {
			log.unhappy(`Token refresh denied`);
			window.location.replace(`/logout`);
			return [{ type: `UNAUTHORISED`, message: res.statusText }, undefined];
		}

		if (res.status >= 400) {
			log.unhappy(`Something went wrong`);
			if (hasSessionExpired()) {
				log.unhappy(`Session has expired. logging out`);
				window.location.replace(`/logout`);
			}
			return [{ type: `NETWORK_API_REQUEST_ERROR`, message: res.statusText }, undefined];
		}

		const newAccessToken = res.headers.get(`x-ins-refreshed-access-token`);
		if (newAccessToken) {
			const payload = {
				...sessionState,
				accessToken: newAccessToken,
			};
			localStorage.setItem(`ins-session-state`, JSON.stringify(payload));
		}

		const result = isNewApi
			? resBody
			: {
					success: resBody.ok,
					status: res.status,
					data: resBody.response,
			  };
		return [undefined, result];
	} catch (e: unknown) {
		// Network errors (via fetch) will also be caught here, see https://www.npmjs.com/package/node-fetch#handling-exceptions
		const err = e as Error;

		if (err instanceof SyntaxError) {
			log.debug(`JSON parse syntax error`, { err: err.message, name: err.name });
			return [{ type: `PARSE_API_REQUEST_ERROR`, message: err.message }, undefined];
		}

		if (err instanceof TypeError) {
			log.debug(`Network error encountered by fetch()`, { err: err.message, name: err.name });
			return [{ type: `NETWORK_API_REQUEST_ERROR`, message: err.message }, undefined];
		}

		log.debug(`Unknown handleAPIRequest error`, { err: err.message, name: err.name });
		return [{ type: `UNKNOWN_HANDLE_API_REQUEST_ERROR`, message: err.message }, undefined];
	}
};
