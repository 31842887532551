import jwtDecode from 'jwt-decode';
import { log } from '../utils/logger';

import type { IInsSessionState } from '../definitions';

type SessionStateError = `DECODE_TOKEN_ERROR`;

interface IAuthSessionError {
	type: SessionStateError;
	message: string;
}

export type TokenVersion = `v1` | `v2`;

/**
 * Set the session state
 * @param accessToken - The access token (string)
 */
export const setSessionState = async (accessToken: string, version: TokenVersion): Promise<[IAuthSessionError?]> => {
	try {
		const decodedToken = (await jwtDecode(accessToken)) as Record<string, any>;
		const payload = {
			accessToken: accessToken,
			authenticated: true,
			dateLoggedIn: new Date(),
			email: version === `v1` ? decodedToken.data.email : decodedToken.tokenData.email,
			credentialId: version === `v1` ? decodedToken.data.credential_id : decodedToken.tokenData.credential_id,
			patientId: version === `v1` ? decodedToken.data.user_id : decodedToken.tokenData.user_id,
			errorMessage: undefined,
			loading: false,
			expiresAt: new Date(decodedToken.exp * 1000),
		};
		//log.happy(`Setting session state`, { payload });
		localStorage.setItem(`ins-session-state`, JSON.stringify(payload));
		return [undefined];
	} catch (err: unknown) {
		const error = err as Error;
		log.unhappy(`Unable to set session state: ${error.message}`);
		return [{ type: `DECODE_TOKEN_ERROR`, message: error.message }];
	}
};

export const getSessionState = (): IInsSessionState | undefined => {
	try {
		const sessionState = localStorage.getItem(`ins-session-state`);
		if (!sessionState) return;
		const sessionStateJSON = JSON.parse(sessionState) as IInsSessionState;
		return sessionStateJSON;
	} catch (error: unknown) {
		return;
	}
};

export const isAuthenticated = (): boolean => {
	const sessionState = getSessionState();
	return sessionState?.authenticated ?? false;
};

export const deleteState = (): void => {
	localStorage.removeItem(`ins-session-state`);
	localStorage.removeItem(`ins-external-provider-auth-session-id`);
};

export const getEmailFromState = (): string | undefined => {
	const state = getSessionState();
	return state?.email;
};

export const getPatientIdFromState = (): string | undefined => {
	const state = getSessionState();
	return state?.patientId;
};

export const setExternalProviderAuthSessionId = (sessionId: string): void => {
	localStorage.setItem(`ins-external-provider-auth-session-id`, sessionId);
};

export const getExternalProviderAuthSessionId = (): string => {
	const sessionId = localStorage.getItem(`ins-external-provider-auth-session-id`) ?? ``;
	return sessionId;
};

export const hasExternalProviderAuthSession = (): boolean => {
	return !!getExternalProviderAuthSessionId();
};

export const hasSessionExpired = (): boolean => {
	const state = getSessionState();
	if (!state) return false;
	return state.expiresAt > new Date();
};
