import { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useModifyPageTitle } from '../Hooks/hooks';
import { getExternalProviderAuthSessionId, setSessionState } from '../utils/auth';
import { handleRequest } from '../utils/request';
import { log } from '../utils/logger';

import type { ReactElement } from 'react';

interface IUser {
	token: string;
	refreshToken: string;
	email: string;
}

interface IAuthenticatedUser {
	authenticated: boolean;
	user?: IUser;
}

const Page = (): ReactElement => {
	useModifyPageTitle(``);
	const code = new URLSearchParams(window.location.search).get(`code`);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(``);
	const [completed, setCompleted] = useState(false);

	const exchangeCodeForTokens = async (): Promise<IAuthenticatedUser | null> => {
		const sessionId = getExternalProviderAuthSessionId();
		if (!sessionId) {
			log.unhappy(`Unable to retrieve session information`);
			return null;
		}
		const [error, reqResult] = await handleRequest({
			route: `exchange-code-tokens`,
			data: { sessionId, code },
		});
		if (error || !reqResult || reqResult.status !== 200) {
			return null;
		}
		return reqResult.data as IAuthenticatedUser;
	};

	const renderError = (): ReactElement => {
		return (
			<div
				className={`
				flex items-center justify-center
				custom-h-full
			`}
			>
				<h2 className="font-extralight text-center">
					<span className="block text-xl">{errorMessage}</span>
					<span className="block mt-2">
						<Link to="/" className="custom-explicit-link">
							Return to Home page.
						</Link>
					</span>
				</h2>
			</div>
		);
	};

	useEffect(() => {
		const loadAccessTokens = async (): Promise<void> => {
			setLoading(true);
			const response = await exchangeCodeForTokens();
			setLoading(false);
			if (!response) {
				setErrorMessage(`Somethine went wrong, please try again`);
				return;
			}
			log.happy(`Authenticated: ${response.authenticated}`);
			if (response.authenticated) {
				const [setSessionStateErr] = await setSessionState(response.user?.token ?? ``, `v1`);
				if (setSessionStateErr) {
					log.unhappy(`Session state set error`, { error: setSessionStateErr.message });
					setErrorMessage(setSessionStateErr.message);
					return;
				}
			}
			setCompleted(true);
		};
		void loadAccessTokens();
	}, []);

	return (
		<>
			{loading && `Please wait...`}
			{completed && <Redirect to="/" />}
			{errorMessage && renderError()}
		</>
	);
};

export default Page;
