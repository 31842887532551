import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useModifyPageTitle } from '../Hooks/hooks';
import { hasExternalProviderAuthSession, isAuthenticated } from '../utils/auth';
import Products from './home/Products';
import Logo from './insideout-logo-white.png';
import HealthKeyCredits from './home/HealthKeyCredits';
import Footer from './Footer';

import type { ReactElement } from 'react';

const HomePage = (): ReactElement => {
	useModifyPageTitle(`Home`);
	const [canPurchase, setCanPurchase] = useState(false);

	const AppHeader = (): ReactElement => {
		return (
			<div
				className={`
					sticky
					flex items-center flex-wrap justify-between
					w-full
					px-4 sm:px-5
					py-3 h-22
					border-b bg-primary z-10
				`}
			>
				<div className="flex h-full items-center justify-start w-2/3">
					<Link to="/">
						<div>
							<img src={Logo} className={`mx-auto`} />
						</div>
					</Link>
				</div>
				{(isAuthenticated() || hasExternalProviderAuthSession()) && (
					<div className="flex h-full w-1/3 justify-end text-gray-200 font-medium text-lg">
						<Link to="/logout" className="underline">
							Log Out
						</Link>
					</div>
				)}
			</div>
		);
	};

	return (
		<div className="flex flex-col h-screen justify-between">
			<AppHeader />
			{(isAuthenticated() || hasExternalProviderAuthSession()) && (
				<HealthKeyCredits
					onCanPurchase={(purchase: boolean): void => {
						setCanPurchase(purchase);
					}}
				/>
			)}
			<Products enablePurchase={canPurchase} />
			<Footer />
		</div>
	);
};

export default HomePage;
