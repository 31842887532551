import type { ReactElement } from 'react';

interface IInlineCheckBoxProps {
	id: string;
	checked: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	label: string | ReactElement;
	highlight: boolean;
	className?: string;
}

export const InlineCheckBox = ({
	id,
	checked,
	onChange,
	label,
	highlight,
	className,
}: IInlineCheckBoxProps): ReactElement => {
	const textColour = highlight ? `text-red-600` : `text-gray-600`;
	return (
		<div className={`${className}`}>
			<input
				className="appearance-none h-4 w-4 border border-gray-500 rounded-sm bg-white checked:focus:bg-blue-300 checked:hover:bg-blue-300 checked:active:bg-blue-300 checked:bg-blue-300 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer focus:ring-transparent"
				type="checkbox"
				checked={checked}
				onChange={onChange}
				id={id}
			/>
			<label className={`form-check-label font-medium cursor-pointer text-sm select-none ${textColour}`} htmlFor={id}>
				{label}
			</label>
		</div>
	);
};
