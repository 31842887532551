import { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { deleteState } from '../utils/auth';
import { handleRequest } from '../utils/request';

import type { ReactElement } from 'react';

const Logout = (): ReactElement => {
	const [loading, setLoading] = useState(false);
	const [tokenInvalidated, setTokenInvalidated] = useState(false);

	useEffect(() => {
		const invalidateToken = async (): Promise<void> => {
			setLoading(true);
			await handleRequest({
				route: `logout`,
				query: { deviceId: `web_app` },
			});
			deleteState();
			setLoading(false);
			setTokenInvalidated(true);
		};
		void invalidateToken();
	}, []);

	if (tokenInvalidated) {
		return <Redirect to="/" />;
	}

	return (
		<div
			className={`
				flex items-center justify-center
				custom-h-full
		`}
		>
			<h1 className="text-center font-extralight">{loading && `Please wait...`}</h1>
		</div>
	);
};

export default Logout;
